<template>
  <div class="main">
    <NavBar/>
    <!-- Loading -->
    <div v-if="loading" class="content">
      <div class="loader m-auto"></div>
      <strong v-if="isSubmitted" class="m-auto mt-8">กำลังดำเนินการ กรุณารอสักครู่</strong>
    </div>

    <!-- Content -->
    <div v-else class="content">
      <form v-on:submit.prevent="submit">
        <!-- Header -->
        <div class="row">
          <p v-if="exist" class="project-header">แก้ไขรายวิชา {{ course.type_name }} {{courseNumber}} 
            ({{ course.program_code }}) ภาคเรียนที่ {{ course.semester }} ปีการศึกษา {{ course.year }}</p>
          <p v-else class="project-header">เพิ่มรายวิชา</p>
        </div>

        <!-- Choose -->
        <div class="row">
          <input v-model="isPostCourse" type="radio" value="false" @change="resetPostCourse(false)" class="my-auto mr-4" :disabled="exist">
          <label>เพิ่มรายวิชาใหม่ (โครงงาน 1, สหกิจศึกษา, ศึกษาอิสระ 1, ศึกษาอิสระ 2)</label>
          <input v-model="isPostCourse" type="radio" value="true" @change="resetPostCourse(true)" class="my-auto ml-8 mr-4" :disabled="exist">
          <label>เพิ่มรายวิชาต่อ (โครงงาน 2)</label>
        </div>

        <!-- Warning -->
        <div v-if="exist" class="text-red-500 mb-8">*หากต้องการแก้ไขรายละเอียดของรายวิชา เช่น ปีการศึกษา หลักสูตร รายวิชา ภาคเรียน กรุณาลบรายวิชาและทำการสร้างรายวิชาใหม่*</div>
        <div v-else-if="!exist && !isPostCourse" class="text-red-500 mb-8">
          *กรุณาเลือกข้อมูล ปีการศึกษา, ภาคเรียน, รายวิชา และหลักสูตรให้ถูกต้องก่อนทำการเลือกแบบฟอร์ม หากคุณแก้ไขข้อมูลข้างต้นจะต้องทำการเลือกแบบฟอร์มใหม่ทั้งหมด* 
        </div>

        <!-- Previous course -->
        <div v-if="!exist && isPostCourse" class="form-div">
          <p class="label -ml-4">รายวิชาก่อนหน้า <sup>[1]</sup></p>
          <select v-model="course.prev_course" @change="setPostCourse()" class="col-span-2">
            <option :value="null" class="px-4">ไม่มี</option>
            <option v-for="course in prev_courses" :key="course.id" :value="course.id" class="px-4">{{ course.type_name }} {{ course.prev_course == null ? 1 : 2 }} ({{ course.program_code }}) ภาคเรียนที่ {{ course.semester }} ปีการศึกษา {{ course.year }}</option>
          </select>
          <div class="col-span-6 text-sm"><sup>[1]</sup> ใช้สำหรับรายวิชาที่มีรายวิชาก่อนหน้าและใช้ข้อมูลโครงงานต่อจากรายวิชาก่อนหน้าเท่านั้น เช่น ต้องการสร้างรายวิชาโครงงาน 2 ให้เลือกรายวิชาโครงงาน 1 ที่เคยสร้างไว้ภายในระบบ</div>
        </div>
      
        <!-- Academic year -->
        <div class="form-div">
          <p class="label -ml-4">ปีการศึกษา</p>
          <select v-model="course.year" @change="changeType(course.type)" :disabled="isPostCourse || exist" class="w-5/12">
            <option v-for="year in years" :key="year.id" :value="year">{{ year }}</option>
          </select>
        </div>

        <!-- Semester -->
        <div class="form-div">
          <p class="label -ml-4">ภาคเรียนที่</p>
          <select v-model="course.semester" @change="changeType(course.type)" :disabled="isPostCourse || exist" class="w-5/12">
            <option>1</option>
            <option>2</option>
          </select>
        </div>
        
        <!-- Type -->
        <div class="form-div">
          <p class="label -ml-4">รายวิชา</p>
          <select v-model="course.type" :disabled="isPostCourse || exist" @change="changeType(course.type)" class="col-span-2">
            <option v-if="!isPostCourse" key="PJ" value="PJ">โครงงาน 1</option>
            <option v-else value="PJ">โครงงาน 2</option>
            <option key="CP" value="CP">สหกิจศึกษา</option>
            <option key="IS1" value="IS1">ศึกษาอิสระ 1</option>
            <option key="IS2" value="IS2">ศึกษาอิสระ 2</option>
          </select>
        </div>

        <!-- Program -->
        <div class="form-div">
          <p class="label -ml-4">หลักสูตร</p>
          <select v-model="course.program" @change="changeProgram(course.type)" class="col-span-2" :disabled="isPostCourse || exist">
            <option v-for="program in programs" :key="program.id" :value="program.id">{{ program.name }}</option>
          </select>
        </div>

        <!-- Course code -->
        <div v-if="exist" class="form-div">
          <p class="label -ml-4">รหัสวิชา <sup>[2]</sup></p>
          <input v-model="course.code" type="text" placeholder="รหัสวิชา" class="col-span-2">
          <!-- <div class="col-span-6 text-sm"><sup>[2]</sup> ไม่สามารถแก้ไขรหัสวิชาได้ เนื่องจากใช้ในการติดต่อกับระบบอื่น ๆ ในการดึงข้อมูล หากรหัสวิชาไม่สอดคล้องกันระหว่าง 2 ระบบจะไม่สามารถดึงข้อมูลได้</div> -->
        </div>

        <!-- Course: exist Error -->
        <p v-if="val.course" class="text-error">มีรายวิชาที่สอดคล้องกับข้อมูลที่เลือกภายในระบบอยู่แล้ว ไม่สามารถสร้างรายวิชาใหม่ได้</p>
        <!-- Course: prev course none Error -->
        <p v-if="val.prev_course !== ''" class="text-error">{{ val.prev_course }}</p>
        <hr>

        <!-- Form -->
        <div class="row">
          <p class="label my-auto">แบบฟอร์มเอกสารที่ใช้งาน</p>
          <a v-on:click="addFormTemplate" class="file-button">เพิ่มแบบฟอร์มเอกสาร</a>
          <div v-if="course.prev_course && exist" class="ml-4">
            <router-link :to="'/course/' + course.prev_course +'/edit/'" @click="goPrevCourse()">
              <button class="edit-button">แก้ไขรายวิชาก่อนหน้า</button>
            </router-link>
          </div>
        </div>

        <!-- 101 Form -->
        <div v-if="!isPostCourse" class="form-div mt-8">
          <!-- 101 Form first line -->
          <div class="mx-auto">1.</div>
          <div class="col-span-4 row">
            <input type="text" value="แบบฟอร์มนำเสนอหัวข้อโครงงาน (สำหรับการเพิ่มข้อมูลภายในระบบ)" class="w-full" disabled/>
          </div>
          <div></div>
          
          <!-- 101 Form second line -->
          <div></div>
          <div class="col-span-3">
            <div class="flex flex-row">
              <input v-model="course.form_active_date_from" type="datetime-local" class="px-12">
              <p class="mx-4">ถึง</p>
              <input v-model="course.form_active_date_to" type="datetime-local">
            </div>
          </div>
        </div>

        <!-- Other form -->
        <div v-for="form in forms" :key="form.position" class="form-div mt-8">
          <!-- First line -->
          <!-- Position -->
          <div v-if="isPostCourse" class="mx-auto">{{ form.position }}.</div>
          <div v-else class="mx-auto">{{ form.position + 1 }}.</div>
          
          <!-- Form -->
          <div class="col-span-2">
            <select v-model="form.form_entry" @change="checkCoopForm(form, false)" class="w-full" :disabled="form.selectDisabled">
              <option :key="null" :value="null">เลือกแบบฟอร์ม</option>
              <option v-for="template in templates" :key="template.id" :value="template.id">{{ template.title }}</option>
            </select>
          </div>

          <!-- User type -->
          <div class="col-span-2 row">
            <p>ผู้กรอก</p>
            <select v-model="form.role" class="w-32" :disabled="form.disabled">
              <option id="STD" value="STD">นักศึกษา</option>
              <option id="TCH" value="TCH">อาจารย์</option>
              <option v-show="form.role == 'CMP'" id="CMP" value="CMP">บริษัทสหกิจ</option>
            </select>
          </div>

          <!-- Multiple & Delete form -->
          <div class="sm:-ml-24 xl:-ml-32">
            <label class="my-auto">ส่งหลายครั้ง <input v-model="form.multiple" type="checkbox" class="inline ml-4" :disabled="form.disabled"></label>
            <div v-on:click="removeFormTemplate(form.position)" class="delete-form-button -mt-6 cursor-pointer" style="background-color: #ff0000">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-20" viewBox="0 0 20 20" fill="white"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" /></svg>
            </div>
          </div>
          
          <!-- Second line -->
          <br>
          <!-- Datetime range -->
          <div class="col-span-3">
            <div class="flex flex-row">
              <input v-model="form.active_date_from" type="datetime-local" class="px-12">
              <p class="mx-4">ถึง</p>
              <input v-model="form.active_date_to" type="datetime-local">
            </div>
          </div>
        </div>

        <!-- Course: Form error -->
        <p v-if="val.first_error !== ''" class="text-error">{{ val.first_error }}</p>
        <p v-if="val.second_error" class="text-error">กรุณาเลือกแบบฟอร์มให้ถูกต้อง (หากไม่ต้องการสามารถลบได้ด้วยปุ่มทางขวา)</p>
        <p v-if="val.third_error" class="text-error">ไม่สามารถเลือกแบบฟอร์มเอกสารที่ซ้ำกันได้</p>
        <div v-if="val.other_form.length > 0">
          <p v-for="error in val.other_form" :key="error.id" class="text-error">{{ error }}</p>
        </div>
        <hr>

        <!-- Course notification range -->
        <div class="form-div">
          <p class="label myl-4 -ml-4">ระยะเวลา <sup>[3]</sup></p>
          <div class="col-span-4 grid grid-cols-3">
            <div class="col-span-2 flex flex-row">
              <input v-model="course.active_date_from" type="date">
              <p class="mx-4">ถึง</p>
              <input v-model="course.active_date_to" type="date">
            </div>
          </div>
          <div class="col-span-6 text-sm"><sup>[3]</sup> ใช้สำหรับการแจ้งเตือนทางอีเมล หากไม่อยู่ในช่วงระยะเวลาที่กำหนด ระบบจะไม่ทำการแจ้งเตือนแก่นักศึกษา อาจารย์ และบริษัทสหกิจศึกษา</div>
        </div>

        <!-- Course: Notification range error -->
        <p v-if="val.range != ''" class="text-error">{{ val.range }}</p>

        <!-- Student list file -->
        <div class="form-div">
          <p class="label myl-4 -ml-4">รายชื่อนักศึกษา</p>
          <!-- IF: Create post course -->
          <div v-if="isPostCourse && !exist" class="upload-button">
            <p class="-ml-4">ระบบจะทำการดึงข้อมูลรายชื่อนักศึกษาจากรายวิชาก่อนหน้าอัตโนมัติ</p>
          </div>
          <!-- IF: Edit course -->
          <div v-if="exist" class="upload-button">
            <router-link :to="'/course/' + this.$route.params.cid +'/student/'">
              <button class="file-button">ดูรายชื่อนักศึกษา</button>
            </router-link>
          </div>
          <!-- IF: Create new course -->
          <div v-else-if="!exist && !isPostCourse" class="upload-button">
            <label class="mr-8" id="file-name">ยังไม่มีการอัปโหลดไฟล์</label>
            <label for="students" class="file-button">อัปโหลด</label>
            <input type="file" ref="students" id="students" name="students" accept=".csv" @change="handleFileUpload($event)" class="w-0">
            <small class="mx-4">(รองรับเฉพาะไฟล์ .csv เท่านั้น)</small>
            <small class="cursor-pointer" style="color: blue"><u @click="download()">ตัวอย่างไฟล์รายชื่อนักศึกษา</u></small>
          </div>
        </div>

        <!-- File: Error -->
        <p v-if="val.file && !isPostCourse" class="text-error -mt-4">กรุณาเลือกไฟล์รายชื่อนักศึกษาที่มีนามสกุลไฟล์เป็น .csv </p>
        <hr>
        <button type="submit" value="submit" class="submit-button" :disabled="isSubmitted">บันทึกข้อมูล</button>

        <!-- Warning -->
        <div v-if="validate_error" class="text-red-500 mt-4">พบข้อผิดพลาดในข้อมูลที่กรอก กรุณาตรวจสอบข้อมูลอีกครั้ง (มีการแสดงข้อผิดพลาดภายใต้ข้อมูลที่กรอก)</div>
      </form>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"
import moment from "moment"
import { changeProgramName, academicYear, changeProgramByType } from "../mixins/other"
import { courseValidate } from "../mixins/validate"

export default {
  name: "CourseEdit",
  components: {
    NavBar
  },
  data () {
    return {
      loading: true,
      isSubmitted: false,
      course: {
        id: null,
        code: "",
        type: "PJ",
        prev_course: null,
        semester: 1,
        year: new Date().getFullYear() + 543,
        form_active_date_from: null,
        form_active_date_to: null,
        active_date_from: null,
        active_date_to: null,
        program: 1,
      },
      prev_courses: [],
      courses: [],
      years: [],
      programs: [],
      forms: [],
      templates: [],
      file: null,
      exist: null,
      val: {
        course: false,
        first_error: "",
        second_error: false,
        third_error: false,
        other_form: [],
        range: "",
        file: false,
        prev_course: "",
        pass: false
      },
      isPostCourse: false,
      coop_form: []
    }
  },
  async created() {
    // Permission
    if (this.$store.getters.payload.role  !== "STF") {
      this.$router.push("/")
    }

    this.courses = await this.$store.dispatch("getCourse")
    this.years = academicYear(this.years)
    this.programs = await this.$store.dispatch("getProgram")
    this.programs = changeProgramName(changeProgramByType(this.course.type))
    this.templates = await this.$store.dispatch("getFormTemplate")

    // Edit course
    if (this.$route.params.cid) {
      this.course = await this.$store.dispatch("getCourseById", this.$route.params.cid)
      this.programs = changeProgramName(changeProgramByType(this.course.type))
      this.coop_form = await this.$store.dispatch("getCoopForm", { course: this.course.code, semester: this.course.semester, year: this.course.year })
      // Convert datetime to right format
      this.course.active_date_from = moment(new Date(this.course.active_date_from)).format('YYYY-MM-DD')
      this.course.active_date_to = moment(new Date(this.course.active_date_to)).format('YYYY-MM-DD')
      this.course.form_active_date_from = moment(new Date(this.course.form_active_date_from)).format('YYYY-MM-DDTHH:mm')
      this.course.form_active_date_to = moment(new Date(this.course.form_active_date_to)).format('YYYY-MM-DDTHH:mm')
      // Get course form
      this.forms = await this.$store.dispatch("getFormByCourseId", { id: this.$route.params.cid, type: "all" })
      for (let i = 0; i < this.forms.length; i++) {
        this.forms[i].active_date_from = moment(new Date(this.forms[i].active_date_from)).format('YYYY-MM-DDTHH:mm')
        this.forms[i].active_date_to = moment(new Date(this.forms[i].active_date_to)).format('YYYY-MM-DDTHH:mm')
        if (this.forms[i].exam_type != null) 
          this.forms[i].form_entry = "exam_type" + this.forms[i].exam_type
        delete this.forms[i].form_entry_detail
        this.checkCoopForm(this.forms[i], true)
      }
      this.exist = true
      this.isPostCourse = this.course.prev_course == null ? false : true

      // Check if this course is coop or not
      if (this.course.type == 'CP') {
        for (let i in this.coop_form) { 
          this.templates.push({
            id: 'exam_type' + this.coop_form[i]['exam_type_id'],
            exam_type: this.coop_form[i]['exam_type_id'],
            title: this.coop_form[i]['name'],
            coop_form_type: this.coop_form[i]['type']
          })
        }
      }else if (this.course.type == 'IS'){
        this.course.type = this.course.type + this.course.type_is
      }
      document.title = "แก้ไขรายวิชา " + this.course.name + " | Carbon"
    }

    // Create course
    else {
      this.exist = false
      this.prev_courses = await this.$store.dispatch("getAvailableCourse")
      this.prev_courses.sort(function(c1, c2) { return c2.year - c1.year })
      document.title = "เพิ่มรายวิชา | Carbon"
    }

    this.loading = false
  },
  computed: {
    courseNumber(){
      if (this.course.type === 'PJ'){
        if (this.course.prev_course) return 2
        else return 1
      } else if (this.course.type.includes('IS')){
        return this.course.type_is
      }
      return 'N/A'
    }
  },
  methods: {
    async submit() {
      if(!this.isSubmitted) {
        this.isSubmitted = true
        this.loading = true
        const readOnlyProperties = ['program_name', 'program_code']
        let course = this.course
        readOnlyProperties.forEach((prop) => {
          if (Object.prototype.hasOwnProperty.call(course, prop))
            delete course[prop]
        })

        let forms = []
        for (let i = 0; i < this.forms.length; i++) {
          if (this.forms[i].form_entry !== null) {
            if (typeof this.forms[i].form_entry === 'string' && this.forms[i].form_entry.includes("exam_type")) {
              this.forms[i].exam_type = parseInt(this.forms[i].form_entry.slice(9))
            }
            forms.push(this.forms[i])
          }
        }
        const packedData = {course: course, forms: forms}
        let success = false
        courseValidate(packedData, this.courses, this.templates, this.forms, this.val, this.isPostCourse, this.exist)

        if (this.file == null && !this.exist && this.course.prev_course == null) {
          this.val.file = true
        }
        
        // Edit course
        if (this.exist && this.val.pass) {
          success = await this.$store.dispatch("updateCourse", packedData)
        }

        // Create course
        else if (!this.exist && this.val.pass && !this.val.file) {
          try{
            let courseId = await this.$store.dispatch("createCourse", packedData)
            if (this.course.prev_course == null) {
              const fileData = {id: courseId, file: this.file}
              success = await this.$store.dispatch("updateStudent", fileData)
            } 
            
            if (courseId) {
              success = true
            }
          } catch (e) {
            alert(e.response.data)
          }
          
          
        } 
        
        // Error
        else {
          this.validate_error = true
        }
        
        if (success) {
          this.$router.push(`/course/`)
        }
        this.isSubmitted = false
        this.loading = false
      }
    },
    addFormTemplate() {
      let template = {
        position: this.forms.length + 1,
        multiple: false,
        id: null,
        course: null,
        form_entry: null,
        exam_type: null,
        coop_form_type: null,
        role: 'STD',
        disabled: false,
        active_date_from: null,
        active_date_to: null,
      }
      this.forms.push(template)
    },
    removeFormTemplate(position) {
      if (confirm("หากทำการลบแบบฟอร์ม ระบบจะทำการลบข้อมูลทั้งหมดที่นักศึกษาหรืออาจารย์\nกรอกในแบบฟอร์มเอกสารนั้น ๆ คุณต้องการลบหรือไม่") == true) {
        const target = this.forms.find(form => form.position === position)
        this.forms.splice(this.forms.indexOf(target), 1)
        for (let i = 0; i < this.forms.length; i++)
          this.forms[i].position = i+1
      }
    },
    handleFileUpload(event) {
      document.getElementById("file-name").innerHTML = "ยังไม่มีการอัปโหลดไฟล์"
      this.file = event.target.files[0]
      document.getElementById("file-name").innerHTML = event.target.files[0].name
    },
    async resetPostCourse(bool) {
      this.templates = await this.$store.dispatch("getFormTemplate")
      this.programs = changeProgramName(changeProgramByType("PJ"))
      this.isPostCourse = bool
      this.course.prev_course = null
      this.file = null
      this.course.type = 'PJ'
      this.course.program = 1
      this.course.semester = 1
      this.course.year = new Date().getFullYear() + 543
      this.forms = []
      this.val = {
        course: false,
        first_error: "",
        second_error: false,
        third_error: false,
        other_form: [],
        prev_course: "",
        range: "",
        file: false
      }
      if (this.isPostCourse) {
        this.addFormTemplate()
      }
    },
    setPostCourse(){
      let course = this.courses.filter((course) => { return course.id == this.course.prev_course })[0]
      if (course.semester == 2) {
        this.course.year = course.year + 1
        this.course.semester = 1
      } else if (course.semester == 1) {
        this.course.semester = 2
        this.course.year = course.year
      }
      this.course.program = course.program
      this.course.type = course.type
    },
    async download() {
      await this.$store.dispatch("downloadDocumentFile", { id: 1, name: "ไฟล์รายชื่อนักศึกษา.csv", type: "document" })
    },
    async changeType(type) {
      this.programs = changeProgramName(changeProgramByType(type))
      this.forms = []
      if (type.includes('IS')) {
        this.course.program = 4
      } else {
        this.course.program = 1
      }
      this.changeProgram()
    },
    async changeProgram() {
      this.templates = await this.$store.dispatch("getFormTemplate")
      this.forms = []
      if (this.course.type == 'CP') {
        let code =  this.course.program == 1 ? "06016393" : this.course.program == 2 ? "06026130" : "06036046"
        this.coop_form = await this.$store.dispatch("getCoopForm", { course: code, semester: this.course.semester, year: this.course.year })
        for (let i in this.coop_form) { 
          this.templates.push({
            id: 'exam_type' + this.coop_form[i]['exam_type_id'],
            exam_type: this.coop_form[i]['exam_type_id'],
            title: this.coop_form[i]['name'],
            coop_form_type: this.coop_form[i]['type']
          })
        }
      }
    },
    checkCoopForm(form, isExist) {
      if (typeof form.form_entry === 'string') {
        let form_entry = form.form_entry
        let cf = this.coop_form.find((form) => form.exam_type_id == form_entry.slice(9))
        if (cf.for_guest === false) {
          form.role = 'TCH'
        } else if (cf.for_guest === true) {
          form.role = 'CMP'
        }
        form.selectDisabled = false
        form.disabled = true
        form.coop_form_type = cf.type
        if (isExist) {
          form.disabled = true
          form.selectDisabled = true
        }
      } else {
        form.disabled = false
        form.selectDisabled = false
        form.coop_form_type = null
        form.exam_type = null
        if (this.exist !== null) {
          form.role = 'STD'
        }
        if (isExist) {
          form.disabled = true
          form.selectDisabled = true
        }
      }
    },
    goPrevCourse() {
      window.location.assign(this.$router.resolve({ name: this.$route.name, params: { cid: this.course.prev_course }}).href)
      this.$router.go()
    }
  }
}
</script>
